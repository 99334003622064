/** @format */

.shake {
    animation: shake 0.2s ease-in-out;
}

.down-animation {
    animation-name: dropDown;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}
.nav-animation {
    animation-name: navBar;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}
.walletModal-animation {
    animation-name: walletModal;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

.down-animation-delay-1000 {
    animation-name: dropDown;
    animation-duration: 0.25s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

.down-animation-15 {
    animation-name: dropDown15;
    animation-duration: 0.15s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

.down-animation-30 {
    animation-name: dropDown30;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

.arrow_rotate_up_animation {
    transition: all 0.2s;
    transform: rotate(-180deg);
}

.arrow_rotate_down_animation {
    transition: all 0.2s;
    transform: rotate(0);
}

.bg-skeleton {
    height: 100%;
    background-color: #d1d1d1;
    background-image: linear-gradient(
        110deg,
        #d1d1d1 8%,
        #e6e6e6de 18%,
        #d1d1d1 33%
    );
    background-size: 200% 200%;
    animation: 1s shine linear infinite;
}
.skeleton {
	background-color: #828282;
	background-image: linear-gradient(110deg, #828282 8%, #a7a1a1 18%, #828282 33%);
	background-size: 200% 100%;
	animation: 1s shine linear infinite;
}
@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

@keyframes shake {
    0% {
        transform: translate(4px, 0px);
    }
    20% {
        transform: translate(-9px, 0px);
    }
    40% {
        transform: translate(12px, 0px);
    }
    60% {
        transform: translate(-4px, 0px);
    }
    80% {
        transform: translate(2px, 0px);
    }
    100% {
        transform: translate(-1px, 0px);
    }
}

@keyframes dropDown {
    0% {
        opacity: 0.1;
        transform: translateY(calc(-60px));
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes navBar {
    0% {
        opacity: 0.1;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes walletModal {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes dropDown15 {
    0% {
        opacity: 0.1;
        transform: translateY(calc(-15px));
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes dropDown30 {
    0% {
        opacity: 0.1;
        transform: translateY(calc(-30px));
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
