/** @format */

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Mờ phía sau */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999999999999;
}
.modalOverlayPaddingLeft {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Mờ phía sau */
	display: flex;
	margin-left: 10%;
	justify-content: center;
	align-items: center;
	z-index: 99999999;
}
.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99999999;
}
.modal1 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99999999;
}
@media screen and (max-width: 768px) {
}
