// index.module.scss
.walletContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it appears on top of other content */
  }
  
  .walletButton {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
    padding: 12px 20px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #45a049;
    }
  }
  