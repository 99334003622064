/** @format */

// :root {
// 	--learn-main-width: 50%;
// 	--header-height: 82px;
// 	--learn-top-height: 140px;
// 	--game-top-height: 120px;
// 	--learn-list-course-top-height: 120px;
// 	--generalColumnWidth: 56vw;
// 	--notebook-level-bar-height: 110px;
// 	--mobile-navbar-height: 60px;

// 	// THA edit:
// 	--card-padding: 10px;
// }
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}
html {
	margin: 0;
	padding: 0;
	color: var(--text-color);
}
body {
	font-size: 1.6rem;
}
html {
	font-size: 62.5%;
}

body {
	text-rendering: optimizespeed;
}

ol,
ul {
	list-style: none;
}

a {
	text-decoration: none;
}

/* Scrollbar CSS */
html *::-webkit-scrollbar {
	border-radius: 0;
	width: 8px;
	background-color: #f0f0f0;
}

html *::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #c7c7c7;
}

html *::-webkit-scrollbar-track {
	border-radius: 0;
	// background-color: rgba(0, 0, 0, 0);
}
#draw {
	position: relative;
	div[id^="draggable"] {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
	}
}

button,
input,
[tabindex] {
	outline: none;
	border: none;
	color: #000;
	font-family: "Quicksand";
}

// Set input placeholder color
::-webkit-input-placeholder {
	/* Edge */
	color: rgba(218, 218, 218, 1);
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: rgba(218, 218, 218, 1);
}

::placeholder {
	color: rgba(218, 218, 218, 1);
}

@font-face {
	font-family: "Quicksand";
	src: url("../assets/fonts/Quicksand/Quicksand-Medium.ttf");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Quicksand";
	src: url("../assets/fonts/Quicksand/Quicksand-SemiBold.ttf");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Quicksand";
	src: url("../assets/fonts/Quicksand/Quicksand-Bold.ttf");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Quicksand";
	src: url("../assets/fonts/Quicksand/Quicksand-Light.ttf");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Quicksand";
	src: url("../assets/fonts/Quicksand/Quicksand-Regular.ttf");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Quicksand";
	src: url("../assets/fonts/Poppins/Poppins-Medium.ttf");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins/Poppins-SemiBold.ttf");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins/Poppins-Bold.ttf");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins/Poppins-Light.ttf");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins/Poppins-Regular.ttf");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "BerlinSansFBDemi";
	src: url("../assets/fonts/BerlinSans/Berlin-Sans-FB-Demi-Bold.ttf");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Berlin Sans FB Demi";
	src: url("../assets/fonts/BerlinSans/Berlin-Sans-FB-Demi-Bold.ttf");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Berlin Sans FB";
	src: url("../assets/fonts/berlin-sans-fb/BRLNSR.TTF");
	font-weight: 12;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: "Myriad";
	src: url("../assets/fonts/myriad-pro/MyriadPro-Light.otf");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@media screen and (max-width: 1600px) {
	:root {
		--learn-main-width: 55%;
		--header-height: 65px;
		--learn-top-height: 130px;
		--game-top-height: 110px;
	}
}

@media screen and (max-width: 1440px) {
	:root {
		--learn-main-width: 60%;
		--game-top-height: 70px;
	}
}

@media only screen and (max-width: 1080px) {
	:root {
		--learn-main-width: 70%;
		--header-height: 80px;
		--learn-top-height: 95px;
		--generalColumnWidth: 100%;
		--notebook-level-bar-height: 95px;
	}
}

@media screen and (max-width: 768px) {
	:root {
		--learn-main-width: 100%;
		--learn-top-height: 65px;
	}

	/* Scrollbar CSS */
	html *::-webkit-scrollbar {
		width: 4px;
	}

	:root {
		--header-height: 45px;
	}
}

@media screen and (max-width: 475px) {
	:root {
		--learn-list-course-top-height: 80px;
	}

	body {
		font-size: max(1.4rem, 14px);
	}
}
