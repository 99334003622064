/** @format */
.main1 {
	height: auto;
	width: 100vw;
	height: 100vh;
	background-image: url('../../../assets/images/teleImage/4.webp') !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.main {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	max-width: 400px;
	margin: 0 auto;
}

.background__start {
	background-image: url('../../../assets/roll/slime_bgc.png') !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
}

.bgc_start_farming {
	background-image: url('../../../assets/roll/start/bgc_slime_farming.png') !important;
	border: 1px solid rgba(125, 153, 188, 0.5);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.bottomNavigation {
	max-width: 400px;
	display: flex;
	justify-content: space-around;
	position: fixed;
	bottom: 0;
	border-top: 1px solid #ccc;
	padding: 4px 0;
	border-radius: 6px;
	border: 1px solid #7d99bc;
	box-shadow: 0px 0px 2px 0px #ffe69b;
	margin-bottom: 8px;
	width: 100%
}
.confirmPurchaseTitle {
	color: #fff;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #b89b73;
	font-family: 'BerlinSansFBDemi';
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 20px;
}
.confirmPurchaseTitle1 {
	color: #fff;
	margin-top: 20px;
	text-align: center;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: #b89b73;
	font-family: 'BerlinSansFBDemi';
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 10px;
}
.shadowBottom {
	bottom: 0;
	position: fixed;
	width: 400px;
	height: 76px;
	display: flex;
	background-color: rgb(10, 5, 77);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.navItem {
	text-decoration: none;
	color: #000;
	font-size: 16px;
}

.navItem:hover {
	color: #0070f3;
}
.container {
	position: relative;
}

.loadingContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.loadingBar {
	width: 100%;
	height: 10px;
	background-color: #ccc;
	border-radius: 5px;
	overflow: hidden;
	margin-top: 10px;
}

.loadingBarProgress {
	height: 100%;
	background-color: #0070f3;
	transition: width 0.3s ease;
}
.backgroundWebGlStyle {
	background-image: url('../../../assets/images/teleImage/loadingImg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
}
.loadingContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
}

.waveLoading {
	position: relative;
	width: 500px;
	height: 50px;
	margin-top: 6px;
	overflow: hidden;
}

.waves {
	left: 3.6%;
	bottom: 8px;
	position: absolute;
	width: 93%;
	height: 34px;
	border-radius: 40px;
	z-index: 100;
}
.position1 {
	position: absolute;
	width: 500px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.parallax > use {
	animation: moveWaves 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 7s;
}

.parallax > use:nth-child(2) {
	animation-delay: -3s;
	animation-duration: 10s;
}

.parallax > use:nth-child(3) {
	animation-delay: -4s;
	animation-duration: 13s;
}

.parallax > use:nth-child(4) {
	animation-delay: -5s;
	animation-duration: 20s;
}
.checkedInStyle {
	display: inline-block;
	justify-self: center;
	align-self: center;
	padding: 10px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: #fefefe;
	text-align: center;
	font-family: 'BerlinSansFBDemi';
	font-size: 16px;
	font-weight: 400;
	left: 18%;
	top: 50%;
	z-index: 2000;
}

@keyframes moveWaves {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
.loadingTextStyle {
	color: #fff;
	text-align: center;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
	font-family: 'BerlinSansFBDemi';
	font-size: 18px;
	font-weight: 400;
}
.randomTextStyle {
	width: 90vw;
	z-index: 120;
	bottom: 15px;
	position: absolute;
	color: #fff;
	text-align: center;
	text-shadow: 0px 2px 0px #0e1848;
	font-family: 'BerlinSansFBDemi';
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}
