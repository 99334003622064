/** @format */

.body {
    background-image: url("../../../../assets/images/teleImage/vangBtn.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding: 18px;
    width: max-content;
    min-width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.body1 {
    background-image: url("../../../../assets/images/teleImage/timBtn.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding: 18px;
    min-width: 220px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.childrenStyle {
    color: #fffefe;
    text-align: center;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #7d592a;
    font-family: "Berlin Sans FB Demi";
    font-size: 18px;
    font-weight: 700;
}
.childrenStyleDisable {
    color: white;
    text-align: center;
    font-family: "Berlin";
    font-size: 18px;
    font-weight: 700;
    -webkit-text-stroke-width: 1.5px;
}
.textStyle {
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
