.popup__usdt {
	text-align: center;
	&_title {
		color: #fff;
		text-align: center;
		-webkit-text-stroke-width: 0.5;
		-webkit-text-stroke-color: #b89b73;
		font-family: "BerlinSansFBDemi";
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 1.4px;

		margin-top: 100px;
	}
	&_description {
		color: #fff;
		text-align: center;
		font-family: "BerlinSansFBDemi";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.8px;
		margin-top: 20px;
	}
	&_list_button {
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}
}
.text_button_quickly {
	color: #fffefe;
	text-align: center;
	-webkit-text-stroke-width: 1.0444444417953491;
	-webkit-text-stroke-color: #7d592a;
	font-family: "BerlinSansFBDemi";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
