/** @format */

.main {
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 92vh;
    overflow-y: scroll;
    scrollbar-width: none;
    background-image: url("../../../../../assets/images/teleImage/bgrFriend.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
}
.body {
    border-radius: 16px;
    border: 1px solid rgba(125, 153, 188, 0.5);
    background: linear-gradient(
        180deg,
        rgba(23, 39, 120, 0.8) 0%,
        rgba(39, 78, 189, 0.8) 100%
    );
    backdrop-filter: blur(8px);
}

.body::-webkit-scrollbar {
    display: none;
}
.itemStyles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 16px;
}
.itemTextStyle {
    width: 40vw;
    display: flex;
    align-items: self-start;
    justify-content: flex-start;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
}
.itemTextStyleGray {
    width: 40vw;
    display: flex;
    align-items: self-start;
    justify-content: flex-start;
    color: #a7afe7;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
}
.itemTextStyle {
    width: 40vw;
    display: flex;
    align-items: self-start;
    justify-content: flex-start;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
}
.itemDaily {
    max-width: 200px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px;
    border-radius: 16px;
    border: 1px solid rgba(125, 153, 188, 0.5);
    background: rgba(12, 43, 113, 0.6);
    backdrop-filter: blur(8px);
    justify-content: center;
    align-items: center;
}
.day7Style {
    display: flex;
    justify-content: center;
    align-items: center;
}
.notice {
    display: inline-flex;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    color: #fefefe;
    text-align: center;
    font-family: "BerlinSansFBDemi";
    font-size: 16px;
    font-weight: 400;
    top: 50%;
}
.notice1 {
    display: inline-flex;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    color: #fefefe;
    text-align: center;
    font-family: "BerlinSansFBDemi";
    font-size: 16px;
    font-weight: 400;
    top: 50%;
    left: 20%;
    z-index: 2000;
}
.checkedInStyle {
    display: inline-block;
    justify-self: center;
    align-self: center;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    color: #fefefe;
    text-align: center;
    font-family: "BerlinSansFBDemi";
    font-size: 16px;
    font-weight: 400;
    top: 50%;
    z-index: 2000;
}
.wrapper {
    margin-top: 80px;
    text-align: center;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}
.confirmPurchaseTitle {
    color: #fff;
    text-align: center;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #b89b73;
    font-family: "BerlinSansFBDemi";
    font-size: 23px;
    font-weight: 700;
}
.checkInStyle {
    justify-content: space-between;
    display: flex;
    width: 100%;
    max-width: 400px;
    height: 100px;
    padding: 10px 16px;
    align-items: center;
    border-radius: 16px;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
        ),
        linear-gradient(
            180deg,
            rgba(23, 39, 120, 0.8) 0%,
            rgba(39, 78, 189, 0.8) 100%
        );
}
.checkinTextStyle {
    color: #fff;
    font-family: "BerlinSansFBDemi";
    font-size: 16px;
    font-weight: 400;
}
.selectNetworkStyle {
    border-radius: 16px;
    width: 171px;
    height: 35px;
    flex-shrink: 0;
    background: #2856b5;
    box-shadow: 0px 4px 4px 0px #192d87 inset;
}
//
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 16px;
    background: #2856b5;
    width: 100%;
    max-width: 250px;
    box-shadow: 0px 4px 4px 0px #192d87 inset;
    color: #bccbfe;
    font-family: "BerlinSansFBDemi";
    font-size: 16px;
    font-weight: 400;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    border-radius: 16px;
    background: #2856b5;
    width: 60vw;
    box-shadow: 0px 4px 4px 0px #192d87 inset;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
    color: #bccbfe;
    text-align: center;
    font-family: "BerlinSansFBDemi";
    font-size: 16px;
    font-weight: 400;
}
.titleQuestStyle {
    border-radius: 4px 16px 0px 0px;
    background: #fff;
    display: block;
    width: max-content;
    margin-left: 20px;
    margin-top: 16px;
    padding: 8px 10px;
    text-align: center;
    color: #2344ae;
    font-family: "BerlinSansFBDemi";
    font-size: 18px;
    font-weight: 700;
}

.checkInDesStyle {
    color: #fff;
    margin-top: 2px;
    font-family: "Poppins";
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
}

.dropdownMenu.open {
    max-height: 200px; /* Adjust based on number of items */
    opacity: 1;
}

.dropdownItem {
    padding: 10px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: #f1f1f1;
}
