:root {
	line-height: 1.2;

	// switch this to rem
	--line-height: 1.2;
	--font-size-normal: 16px;
	--font-size-medium: 24px;
	--font-size-large: 32px;
	--font-size-xLarge: 2.5rem;
	--font-size-xxLarge: 48px;
	--font-size-xxxLarge: 56px;
	--font-size-xxxxLarge: 68px;
	--font-size-mega: 120px;
}

:root {
	--learn-main-width: 50%;
	--header-height: 82px;
	--learn-top-height: 140px;
	--game-top-height: 120px;
	--learn-list-course-top-height: 120px;
	--generalColumnWidth: 56vw;
	--notebook-level-bar-height: 110px;
	--mobile-navbar-height: 60px;

	// THA edit:
	
    --theme-primary: #F8AD3E;
	--theme-shadow-primary: #F8AD3ECC;
	--theme-shadow: #e0e0e0;
    --theme-background: white;
	--theme-background-secondary: #FBE9A3;
	--theme-neutral: #F8F8F8;

	--theme-secondary: #FFF7D9;
	--theme-tertiary: #2F80ED;
	--theme-on-secondary: Brown;
	--theme-shadow-secondary: rgba(248, 174, 62, 0.5);
	--theme-gradient-primary:  linear-gradient(to right, #FFCB08, #FF9600);
	--theme-gradient-green:  linear-gradient(to right, #58CC02, #23AC38);

	--theme-popup-modal-background: #00000094;
	--theme-submit-popup-background: #000000B2;

	--theme-correct-state-color: #23ac38;
	--theme-incorrect-state-color: #eb5757;
}

:root {
	--card-padding: 10px;
	--preset-padding0: 24px;
	--preset-padding1: 48px;
}

:root {
	--preset-border-radius-popup: 12px 12px 0px 0px;

	--preset-border-radius-round: 999px; 
	--preset-border-radius0: 15px; 
	--preset-border-radius1: 5vw; 
	--preset-border-radius2: 7vw; 
}

:root {
	--preset-horizontal-spacing0: 20px; 

	--preset-vertical-spacing0: 10px; 
	--preset-vertical-spacing: 15px; 
	--preset-vertical-spacing1: 20px; 
}

:root {
	--shadow-drop-size-secondary: 16px;

	--box-shadow: 0 4px 0 0 var(--theme-shadow);
	--box-secondary-shadow: 0 var(--shadow-drop-size-secondary) 0 0 var(--theme-shadow-secondary);
	--default-border: 2px solid var(--theme-shadow);
	--border-secondary: 2px solid var(--theme-shadow-secondary);
	--linebreak-thickness: 1px;
	--progressbar-thickness: 15px;

	--card-size: 70px
}
